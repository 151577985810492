@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Inter/Inter-Regular.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Inter/Inter-Bold.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Inter/Inter-SemiBold.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Inter/Inter-Medium.ttf'); /* IE9 Compat Modes */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #343a47;
  color: white;
  font-family: 'Inter-Regular', sans-serif;
}

.loginLogo {
  position: absolute;
  width: 120px;
  z-index: 9999;
  top: 25px;
  left: 30px;
  cursor: pointer;
}

.obDecorations {
  overflow: hidden;
}

.obDecorations * {
  position: absolute;
}

.obDecorations > .Rect1 {
  position: relative;
  top: 0;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 480px) {
    display: none;
  }
}

.obDecorations > .Rect2 {
  /* position: relative; */
  float: right;
  top: 0;
  right: 0;
  height: 700px;
  height: 70%;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 480px) {
    display: none;
  }
}

.obDecorations > .Vector1 {
  /* position: relative; */
  width: 50%;
  top: 0;
}

.obDecorations > .Vector2 {
  /* position: relative; */
  float: right;
  right: 0;
  bottom: 0;
  width: 50%;
}

.spotifyButton {
  font-family: Inter-Bold;
  background: #282c32;
  color: white;
}

.spotifyButton:hover {
  background-color: #48945d;
  color: white;
}

.loginSpotifyButton {
  width: 100%;
  border-radius: 10px;
  height: 56px;

  font-family: Inter-Bold;
  font-style: normal;
  font-size: 18px;
  line-height: 134.52%;
  /* or 24px */

  text-align: center;

  @media (max-width: 480px) {
    display: none;
  }
}

.mainGreenButton {
  border-radius: 4px;
  background-color: #13c1b7;
  width: 90px;
  height: 38px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  font-size: 0.8em;
  color: white;
}

.mainGreenButton:hover {
  background-color: #5dbab1;
  color: white;
}

.loginSpotifyWhite {
  width: 20px;
  margin-bottom: 1px;
  margin-right: 10px;

  @media (max-width: 480px) {
    width: 6.5vw;
    min-width: 13px;
  }
}

.login-container {
  width: 90%;
  /* min-height: 500px; */
  left: 50%;
  transform: translate(-50%, 0);
}

.obFooter {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  font-size: 0.8em;
  color: #adb5bd;
  text-align: center;
  background-color: #1d2025;
}

.obFooterRight {
  float: right;
  color: #525f7f;
}

.modal-show {
  opacity: 1 !important;
  z-index: 50 !important;
  background-color: rgba(52, 57, 71, 0.7) !important;
}
